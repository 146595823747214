/* ProductCard.css */
.product-card {
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 8px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.product-card:hover {
  transform: translateY(-5px);
}

.product-image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 10px;
}

.product-name {
  font-size: 1.2rem;
  color: #333;
  margin: 0;
}

.product-category {
  font-size: 0.9rem;
  color: #777;
  margin: 5px 0;
}

.product-price {
  font-size: 1rem;
  color: #007bff;
  margin: 5px 0;
}

.product-rating {
  font-size: 0.9rem;
  color: #555;
  margin: 5px 0;
}
