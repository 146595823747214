.products {
  margin: 2rem;
  display: flex;
  flex-flow: row wrap;
}
.product {
  width: calc(100% / 3 - 2rem);
  background: #fff;
  margin: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem rgba(26, 26, 44, 0.05);
  display: flex;
  flex-flow: column nowrap;
  transition: transform 0.2s, box-shadow 0.2s;
}
.product:hover {
  transform: scale(1.025);
  box-shadow: 0 1rem 1rem -0.5rem rgba(26, 26, 44, 0.1);
}
.product__category {
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.75rem;
  border-radius: 1rem;
  font-size: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  background: rgba(26, 26, 44, 0.05);
  width: fit-content;
  color: #1a1a2c;
}
.product__image {
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  position: relative;
  overflow: hidden;
  display: block;
  border-radius: 0.5rem 0.5rem 0 0;
}
.product__image .banner {
  position: absolute;
  transform: translate(50%, -100%) rotate(45deg);
  transform-origin: 0% 0%;
  top: 0;
  right: 0;
  z-index: 3;
  padding: 0.33rem 3rem;
  background: #f90;
  color: #fff;
  font-size: 0.85rem;
  font-weight: 700;
  box-shadow: 0 0.25rem 1rem rgba(26, 26, 44, 0.25);
}
.product__image::before {
  content: "View Product";
  position: absolute;
  padding: 0.5rem 1rem;
  background: #fff;
  box-shadow: 0 1rem 1rem -0.5rem rgba(26, 26, 44, 0.5);
  color: #1a1a2c;
  border-radius: 0.5rem;
  z-index: 3;
  transform: translate(-50%, -25%);
  opacity: 0;
  top: 50%;
  left: 50%;
  white-space: nowrap;
  transition: all 0.2s;
}

.product__image::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: all 0.2s;
}
.product:hover .product__image::before {
  transform: translate(-50%, -50%);
  opacity: 1;
}
.product:hover .product__image::after {
  opacity: 1;
}
.product__image img {
  width: auto;
  height: 100%;
  object-fit: contain;
  position: absolute;
  padding: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
}
.product__details {
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  flex: 1;
}
.product__name {
  height: 4rem;
  overflow: hidden;
  font-size: 1.12rem;
}
.product__footer {
  margin-top: auto;
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product__price del {
  color: #dc143c;
}
.product__price b {
  font-size: 1.5rem;
  font-weight: 900;
}
.product__rating {
  font-size: 1.05rem;
}

@media (max-width: 850px) {
  .product {
    width: calc(100% / 2 - 2rem);
  }
}

@media (max-width: 550px) {
  .products {
    margin: 0;
  }
  .product {
    width: calc(100% - 3rem);
    margin: 0.75rem 1.5rem;
  }
}
