.app {
  width: 100vw;
  min-height: 100vh;
  position: relative;
  background: #fafafa;
  display: flex;
  flex-flow: column nowrap;
}

.app__inner {
  padding-left: clamp(80px, 10vw, 100px);
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}
.whatsapp_icon {
  z-index: 999;
}

img {
  border: none;
}

@media (max-width: 550px) {
  .app__inner {
    padding: 0;
  }
}
