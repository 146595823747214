@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;500;700;800;900&display=swap);
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.33;
  color: #1a1a2c;
  background-color: #fafafa;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;
}

h1 {
  font-size: calc(1.75rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: calc(1.5rem + 0.9vw);
}

@media (min-width: 1200px) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  h3 {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  h4 {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  text-decoration: none;
  color: #1a1a2c;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
  white-space: normal;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

html,
.noScrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

html::-webkit-scrollbar,
.noScrollbar::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

.sidebar {
  min-width: 5rem;
  width: clamp(80px, 10vw, 100px);
  height: 100vh;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  box-shadow: 0 0 1rem rgba(26, 26, 44, 0.05);
  background: #fff;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 0;
}
.sidebar::-webkit-scrollbar {
  display: none;
}
.sidebar__icon {
  width: 6rem;
  height: auto;
}
.sidebar__menu {
  display: flex;
  flex-flow: column nowrap;
}
.sidebar__menuItem {
  margin: 1rem auto;
  position: relative;
}
.sidebar__menuIcon {
  transform: scale(0.9);
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1) !important;
}
.sidebar__menuItem.active .sidebar__menuIcon {
  transform: scale(1);
  fill: #1a1a2c !important;
  stroke-width: none !important;
  stroke: transparent !important;
}
.sidebar__menuItem:hover .sidebar__menuIcon {
  transform: scale(1.05);
}
.sidebar__menuItem .sidebar__itemValue {
  position: absolute;
  padding: 4px 4px 5px 4px;
  font-size: 10px;
  text-align: center;
  min-width: 18px;
  height: 19px;
  background: #f90;
  color: #fff;
  font-weight: 700;
  left: calc(100% - 12px);
  bottom: calc(100% - 12px);
  margin: 0;
  border-radius: 99px;
}
.sidebar__avatar {
  width: 3rem;
  height: 3rem;
  border-radius: 99px;
  box-shadow: 0 0.5rem 0.5rem rgba(26, 26, 44, 0.05);
  transition: all 0.2s;
  object-fit: contain;
}
.sidebar__avatar:hover {
  box-shadow: 0 0.5rem 0.75rem rgba(26, 26, 44, 0.15);
  transition: scale(1.05);
}
.app__toolTip {
  border-radius: 0.5rem !important;
  box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}
.app__toolTipRed {
  box-shadow: 0.25rem 0.25rem 0.5rem rgb(220, 20, 60, 0.15) !important;
}

@media (max-width: 550px) {
  .sidebar {
    width: 80px;
    transform: translateX(-80px);
    padding: 1.5rem 0;
    transition: all 0.2s;
  }
  .sidebar.active {
    transform: translateX(0);
  }
  .sidebar__icon {
    transform: translateX(80px);
    transition: all 0.2s;
    width: 4rem;
    height: 4rem;
    padding: 0.5rem;
    border-radius: 99px;
    box-shadow: 0 0.25rem 0.5rem rgba(26, 26, 44, 0.1);
    background: #fff;
  }
  .sidebar.active .sidebar__icon {
    transform: translateX(0);
    box-shadow: 0;
  }
}

.header {
  position: relative;
}
.header__nav {
  padding: 3rem;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header__search,
.header__back {
  padding: 0.33rem 0.15rem 0.33rem 0.66rem;
  background: #fff;
  box-shadow: 0 0.25rem 0.5rem rgba(26, 26, 44, 0.05);
  border-radius: 0.5rem;
  font-size: 0.85rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  position: relative;
}
.header__back {
  margin-right: auto;
  padding: 0.33rem 0.66rem;
  display: inline-block;
  border: none;
}
.header__searchIcon {
  opacity: 0.75;
  transform: scale(0.95);
}
.header__search input {
  background: none;
  border: none;
  width: 100%;
  height: 100%;
  padding: 0 0.5rem;
}
input:focus {
  outline: none;
}
input::-webkit-input-placeholder {
  color: #1a1a2c;
  opacity: 0.5;
}
input:-ms-input-placeholder {
  color: #1a1a2c;
  opacity: 0.5;
}
input::-ms-input-placeholder {
  color: #1a1a2c;
  opacity: 0.5;
}
input::placeholder {
  color: #1a1a2c;
  opacity: 0.5;
}
.header__logo {
  height: 4rem;
  width: auto;
  margin-left: 1rem;
}

@media (max-width: 550px) {
  .header__nav {
    padding: 1.5rem;
  }
  .header__logo {
    display: none;
  }
  .header__langDropDown {
    display: none !important;
  }
  .header__search {
    margin-right: 0;
    width: 50%;
  }
  .header__back {
    margin-right: 1rem;
  }
}

.dropDown {
  padding: 0.33rem 0.15rem 0.33rem 0.66rem;
  background: #fff;
  box-shadow: 0 0.25rem 0.5rem rgba(26, 26, 44, 0.05);
  border-radius: 0.5rem;
  font-size: 0.85rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  position: relative;
}
.dropDown__icon {
  opacity: 0.75;
  transform: rotate(0) scale(0.8, 0.85);
  transition: transform 0.2s !important;
}
.dropDown.dropDownOpen .dropDown__icon {
  transform: rotate(-180deg) scale(0.8, 0.85);
}
.dropDown__menu {
  padding: 0;
  background: #fff;
  border-radius: 0.5rem;
  position: absolute;
  top: calc(100% + 0.5rem);
  right: 0;
  list-style-type: none;
  box-shadow: 0 0.25rem 0.5rem rgba(26, 26, 44, 0.15);
  z-index: 5;
  overflow: hidden;
  height: 11.25rem;
  overflow: scroll;
}
.dropDown__menu::-webkit-scrollbar {
  display: none;
}
.dropDown__menu li {
  transition: background 0.2s;
  padding: 0.5rem 2rem 0.5rem 1rem;
  white-space: nowrap;
}
.dropDown__menu li:first-of-type {
  padding-top: 0.75rem;
}
.dropDown__menu li:last-of-type {
  padding-bottom: 0.75rem;
}
.dropDown__menu li:hover {
  background: rgba(0, 0, 0, 0.05);
}

.results {
  max-width: 600px;
  width: calc(100% - 2rem);
  max-height: 24rem;
  overflow: scroll;
  margin: 1rem;
  border-radius: 1rem;
  box-shadow: 0 0 0 2px white, 0 0.25rem 1rem -0.25rem rgba(26, 26, 44, 0.15);
  position: absolute;
  top: calc(100% + 0.5rem);
  right: 3rem;
  z-index: 9999;
  background: #fff;
}
.result {
  display: flex;
  align-items: center;
  transition: background 0.2s;
  cursor: pointer;
}

.result:hover {
  background: rgba(26, 26, 44, 0.05);
}
.result__image {
  width: 4rem;
  flex: 0 0 4.5rem;
  height: 0;
  padding-bottom: 4.5rem;
  background: #fff;
  position: relative;
}
.result__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 0.75rem;
  position: absolute;
}

.result span {
  overflow: hidden;
  padding: 1rem;
  padding-left: 0.5rem;
}

.result__title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
  margin: 0;
  font-size: 0.95rem;
  line-height: 1.2;
  font-weight: 500;
}
.result__desc {
  font-size: 0.85rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  display: block;
  opacity: 0.7;
}

@media (max-width: 750px) {
  .results {
    width: calc(100% - 3rem);
    max-height: 22rem;
    overflow: scroll;
    margin: 1rem 1.5rem;
    right: 0;
    top: calc(100%);
    box-shadow: 0 0 0 2px white,
      0 0.25rem 1.5rem -0.25rem rgba(26, 26, 44, 0.25);
  }
}

.footer {
  margin-top: auto;
  background: #eaeaea;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height:-webkit-fit-content;
  height:-moz-fit-content;
  height:fit-content;
}
.footer__inner {
  padding-left: clamp(80px, 10vw, 100px);
  max-width: 1440px;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  margin: 0 auto;
  width: 100%;
}
.footer__disclaimer {
  font-size: 16px;
  background: #1a1a2c;
  color: #fff;
  padding: 1rem 3rem;
}
.footer__links {
  display: flex;
  padding: 3rem;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height:-webkit-fit-content;
  height:-moz-fit-content;
  height:fit-content;

}
.footer__row {
  margin-right: 3rem;
  width: calc(25% - 3rem);
}
.footer__row ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.footer__row h6 {
  margin-bottom: 0.5rem;
  white-space: nowrap;
}
.footer__row ul li {
  padding: 0;
  margin-bottom: 0.25rem;
  font-size: 0.85rem;
  opacity: 0.6;
  color: #1a1a2c;
  transition: all 0.2s;
  white-space: nowrap;
}
.footer__row ul li:hover {
  opacity: 1;
}
.footer__bottom {
  padding: 2rem 3rem;
  display: flex;
  align-items: center;
  background: #eaeaea;
  border-top: 1px solid rgba(26, 26, 44, 0.05);
}
.footer__logo {
  height: 6rem;
  width: auto;
  margin-right: 1rem;
}
.footer__copy {
  font-size: 14px;
  white-space: nowrap;
  opacity: 0.75;
}
.footer__copy a {
  color: #595b83;
  line-height: 1.2;
  transition: all 0.2s;
  border-bottom: 1px dotted #595b83;
}
.footer__copy a:hover {
  color: #dc143c;
  border-bottom: 1px dotted #dc143c;
}
.footer__dropDownSpan {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.footer__currDropDown {
  margin-right: 0;
}
.footer__dropDown .dropDown__menu {
  top: auto;
  bottom: calc(100% + 0.5rem);
}

@media (max-width: 550px) {
  .footer__inner {
    padding: 0;
  }
  .footer__links {
    padding: 5rem 1.5rem 1.5rem 1.5rem;
    flex-flow: column nowrap;
  }
  .footer__row {
    margin: 0;
    margin-bottom: 1.5rem;
  }
  .footer__disclaimer {
    padding: 1.5rem;
  }
  .footer__copy {
    padding: 0.5rem 0 1rem 0;
  }
  .footer__dropDownSpan {
    margin: 0;
  }
  .footer__bottom {
    padding: 1.5rem;
    flex-flow: column nowrap;
    justify-content: center;
  }
  .footer__langDropDown,
  .footer__currDropDown {
    margin: 0.5rem;
  }
}

.buttons {
  display: flex;
}
.buttons button + button {
  margin-left: 1rem;
}
.buttons button {
  border: none;
  color: #fff;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-weight: 700;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttons button:disabled {
  opacity: 0.5;
}
.buttonSecondary {
  background: #1a1a2c;
  box-shadow: 0 0.5rem 1rem rgba(26, 26, 44, 0.25);
}
.buttonSecondary.outline {
  color: rgba(26, 26, 44, 0.5);
  background: none;
  border: 2px solid rgba(26, 26, 44, 0.25);
  box-shadow: 0 0.5rem 1rem rgba(26, 26, 44, 0.1);
}
.payment .buttonSecondary.outline {
  color: rgba(99, 91, 255, 0.9);
  background: none;
  border: 2px solid rgba(99, 91, 255, 0.5);
  box-shadow: 0 0.5rem 1rem rgba(99, 91, 255, 0.15);
}
.buttonRed {
  background: #dc143c;
  color: #fff;
  border: none;
  height: 1.5rem;
  border-radius: 0.25rem;
  margin-left: 0.5rem;
}
.buttonPrimary {
  background: #f90;
  box-shadow: 0 0.5rem 1rem rgba(255, 153, 0, 0.25);
  padding: 0.5rem 1.5rem !important;
}
.buttonPrimary .MuiSvgIcon-root {
  margin-right: 0.5rem;
}
.buttonPrimary:hover {
  transform: scale(1.05);
  box-shadow: 0 1rem 1rem -0.75rem rgba(255, 153, 0, 0.5);
}
.buttonPri {
  background: green;
  box-shadow: 0 0.5rem 1rem rgba(255, 153, 0, 0.25);
  padding: 0.5rem 1.5rem !important;
}
.buttonPri .MuiSvgIcon-root {
  margin-right: 0.5rem;
}
.buttonPri:hover {
  transform: scale(1.05);
  box-shadow: 0 1rem 1rem -0.75rem rgba(255, 153, 0, 0.5);
}
button:active {
  transform: scale(0.95) !important;
  box-shadow: inset 0 0 0.5rem 0 rgba(0, 0, 0, 0.25) !important;
}
button:focus {
  outline: none;
}

.categories {
  margin: 1rem 3rem -1rem 3rem;
  display: flex;
  white-space: nowrap;
  overflow: scroll;
  -webkit-mask-image: linear-gradient(
    to right,
    transparent 0%,
    black 3%,
    black 97%,
    transparent 100%
  );
}
.categories::-webkit-scrollbar {
  display: none;
}
.categories__category {
  flex: 0 0 auto;
  font-size: 1.15rem;
  position: relative;
  padding: 1rem;
  opacity: 0.5;
  transition: opacity 0.2s;
  cursor: pointer;
  text-transform: capitalize;
}
.categories__category:hover {
  opacity: 1;
}
.categories__category::before {
  position: absolute;
  left: 1rem;
  content: "";
  width: 0;
  height: 1px;
  background: #1a1a2c;
  bottom: 0.5rem;
  transition: all 0.2s;
}
.categories__category:hover::before {
  width: 33%;
}
.categories__category.active {
  font-weight: 700;
  opacity: 1;
}
.categories__category.active::before {
  width: calc(100% - 4rem);
  left: 2rem;
}
.categories__category + .categories__category {
  margin: 0;
  margin-left: 1rem;
}

@media (max-width: 550px) {
  .categories {
    margin: 1.5rem 1.5rem 1rem 1.5rem;
  }
}


.products {
  margin: 2rem;
  display: flex;
  flex-flow: row wrap;
}
.product {
  width: calc(100% / 3 - 2rem);
  background: #fff;
  margin: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem rgba(26, 26, 44, 0.05);
  display: flex;
  flex-flow: column nowrap;
  transition: transform 0.2s, box-shadow 0.2s;
}
.product:hover {
  transform: scale(1.025);
  box-shadow: 0 1rem 1rem -0.5rem rgba(26, 26, 44, 0.1);
}
.product__category {
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.75rem;
  border-radius: 1rem;
  font-size: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  background: rgba(26, 26, 44, 0.05);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #1a1a2c;
}
.product__image {
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  position: relative;
  overflow: hidden;
  display: block;
  border-radius: 0.5rem 0.5rem 0 0;
}
.product__image .banner {
  position: absolute;
  transform: translate(50%, -100%) rotate(45deg);
  transform-origin: 0% 0%;
  top: 0;
  right: 0;
  z-index: 3;
  padding: 0.33rem 3rem;
  background: #f90;
  color: #fff;
  font-size: 0.85rem;
  font-weight: 700;
  box-shadow: 0 0.25rem 1rem rgba(26, 26, 44, 0.25);
}
.product__image::before {
  content: "View Product";
  position: absolute;
  padding: 0.5rem 1rem;
  background: #fff;
  box-shadow: 0 1rem 1rem -0.5rem rgba(26, 26, 44, 0.5);
  color: #1a1a2c;
  border-radius: 0.5rem;
  z-index: 3;
  transform: translate(-50%, -25%);
  opacity: 0;
  top: 50%;
  left: 50%;
  white-space: nowrap;
  transition: all 0.2s;
}

.product__image::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: all 0.2s;
}
.product:hover .product__image::before {
  transform: translate(-50%, -50%);
  opacity: 1;
}
.product:hover .product__image::after {
  opacity: 1;
}
.product__image img {
  width: auto;
  height: 100%;
  object-fit: contain;
  position: absolute;
  padding: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
}
.product__details {
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  flex: 1 1;
}
.product__name {
  height: 4rem;
  overflow: hidden;
  font-size: 1.12rem;
}
.product__footer {
  margin-top: auto;
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product__price del {
  color: #dc143c;
}
.product__price b {
  font-size: 1.5rem;
  font-weight: 900;
}
.product__rating {
  font-size: 1.05rem;
}

@media (max-width: 850px) {
  .product {
    width: calc(100% / 2 - 2rem);
  }
}

@media (max-width: 550px) {
  .products {
    margin: 0;
  }
  .product {
    width: calc(100% - 3rem);
    margin: 0.75rem 1.5rem;
  }
}

.home__carousel {
  margin: 3rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem rgba(26, 26, 44, 0.2);
  overflow: hidden;
}
.carousel .slide {
  background: #fff !important;
}
.carousel .control-dots .dot {
  box-shadow: 0 0.15rem 0.25rem rgba(26, 26, 44, 0.5);
}

@media (max-width: 550px) {
  .home__carousel {
    margin: 0;
    border-radius: 0;
  }
}

.login,
.signup,
.passwordReset {
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
}
.form {
  margin: 5rem 3rem 3rem 3rem;
  padding: 2rem 1rem;
  background: #fff;
  background: #fff;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 0.25rem 0.5rem rgba(26, 26, 44, 0.05);
}
.form h4 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}
.form p.error {
  padding: 0.25rem 0.5rem;
  color: #dc143c;
  font-size: 0.85rem;
}
.form .form__error.error {
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  background: rgba(255, 0, 0, 0.05);
  border-radius: 0.5rem;
  width: 100%;
  max-width: 20rem;
  text-overflow: ellipsis;
  overflow: hidden;
}
.form .form__error.error.green {
  background: rgba(0, 200, 0, 0.25);
  color: #2e8b57;
}
.form span,
.form__resetLink {
  text-align: center;
  padding: 0.5rem;
  display: block;
}
.signup .form__resetLink {
  margin-bottom: 1rem;
}
.form hr {
  background: #ddd;
  width: 90%;
  margin: 0.5rem 0;
  margin-left: 5%;
}
.form__element {
  position: relative;
}
.form__element + .form__element {
  margin-top: 1rem;
}
.form__element label {
  position: absolute;
  left: 1rem;
  top: 1rem;
  font-size: 1rem;
  opacity: 0.75;
  transition: all 0.2s;
}
.form__element .form__icon {
  opacity: 0.25;
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.form__element .form__icon.active {
  opacity: 1;
}
.form__element input,
select {
  border: none;
  background: #fafafa;
  border-radius: 0.5rem;
  padding: 1.5rem 0.75rem 0.5rem 0.75rem;
  box-shadow: inset 0 0 0.5rem rgba(26, 26, 44, 0.05);
  outline: none;
  width: 20rem;
  font-weight: 700;
  transition: all 0.2s;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.form__element input[type="password"] {
  padding: 1.5rem 3.5rem 0.5rem 0.75rem;
}
.form__element input:hover,
select:hover {
  box-shadow: inset 0 0 0.75rem rgba(26, 26, 44, 0.15);
}
.form__element input:focus,
select:focus {
  box-shadow: inset 0 0 0 2px rgba(26, 26, 44, 0.25);
}
.form__element input.error {
  box-shadow: inset 0 0 0 2px #dc143c;
}
input.active + label,
input:focus + label,
select.active + label,
select:focus + label {
  left: 0.75rem;
  top: 0.4rem;
  font-size: 0.85rem;
  opacity: 0.25;
}
.form__element button {
  width: 100%;
  padding: 1rem 0.75rem !important;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  transform: none !important;
  margin: 0 !important;
}
.login .button.signup {
  margin-top: 1rem !important;
}

.form__element.buttons {
  flex-direction: column;
}
.form__element button.outline {
  margin-left: 0;
  padding: 0.375rem 0.75rem !important;
  margin-top: 1rem !important;
}

.form__element button.outline img {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}
input[type="checkbox"],
input[type="radio"] {
  width: 0;
  height: 0;
  position: relative;
  display: none;
  visibility: hidden;
}
input[type="checkbox"] + label,
input[type="radio"] + label {
  position: relative;
  border: none;
  border-radius: 0.5rem;
  padding: 1rem;
  padding-left: 3rem;
  outline: none;
  width: 20rem;
  font-weight: 700;
  left: 0;
  opacity: 0.25;
  top: 0;
}
input[type="radio"] + label {
  padding: 0.25rem 1rem 0.25rem 3rem;
  opacity: 1;
}
input[type="checkbox"] + label::before,
input[type="checkbox"] + label::after,
input[type="radio"] + label::before,
input[type="radio"] + label::after {
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  content: "";
  background: #fff;
  border: 2px solid #1a1a2c;
  border-radius: 0.25rem;
  left: 1rem;
}
input[type="radio"] + label::before,
input[type="radio"] + label::after {
  border-radius: 99px;
}
input[type="checkbox"] + label::after,
input[type="radio"] + label::after {
  background: #f90;
  border: none;
  transform: scale(0);
  transition: all 0.2s;
}
input[type="checkbox"]:checked + label,
input[type="radio"]:checked + label {
  left: 0;
  top: 0;
  font-size: 1rem;
  opacity: 1;
}
input[type="checkbox"]:checked + label::after,
input[type="radio"]:checked + label::after {
  transform: scale(0.6);
}

@media (max-width: 550px) {
  .form {
    margin: 1.5rem;
  }
  .form__element input,
  select,
  input[type="checkbox"] + label,
  input[type="radio"] + label {
    width: 100%;
    max-width: 18rem;
  }
}

.orders {
  padding: 5rem 3rem 3rem 3rem;
}
.orders > h4 {
  font-size: 1.5rem;
  margin-bottom: 3rem;
}
.orders__inner {
  display: flex;
  width: calc(100% + 2rem);
  margin-left: -1rem;
  flex-flow: row wrap;
}
.orders .payment__summary {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% / 3 - 2rem);
  width: calc(100% / 3 - 2rem);
  margin: 1rem;
  max-width: auto;
  align-self: stretch;
  display: flex;
  flex-flow: column nowrap;
}
.order__list {
  display: flex;
  overflow: scroll;
  margin: 1rem 0;

  flex: 1 1;
  -webkit-scroll-snap-type: x mandatory;
      -ms-scroll-snap-type: x mandatory;
          scroll-snap-type: x mandatory;
}

.order__item {
  display: flex;
  flex-direction: column;
  min-width: 120px;
  flex: 0.4 1;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
  background: rgba(0, 0, 0, 0.01);
  position: relative;
  overflow: hidden;
  scroll-snap-align: start;
}
.order__quantity,
.order__price {
  position: absolute;
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
  font-size: 0.6rem;
  font-weight: bold;
  background: rgba(26, 26, 44, 0.05);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #1a1a2c;
  left: 1rem;
  bottom: 2.5rem;
}
.order__quantity {
  left: auto;
  right: 0.5rem;
  bottom: auto;
  top: 0.5rem;
}
.order__image {
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  position: relative;
}
.order__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 0.5rem;
  position: absolute;
}
.order__name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.order__item + .order__item {
  margin-left: 1rem;
}
@media (max-width: 850px) {
  .orders .payment__summary {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% / 2 - 2rem);
    width: calc(100% / 2 - 2rem);
  }
}

@media (max-width: 550px) {
  .orders {
    padding: 3rem 1.5rem;
  }
  .orders__inner {
    flex-flow: column nowrap;
    width: 100%;
    margin: 0;
  }
  .orders .payment__summary {
    flex: 0 0 100%;
    width: 100%;
    margin: 0.75rem 0;
  }
}

.cart {
  padding: 5rem 3rem 3rem 3rem;
}
.cart > h4 {
  font-size: 1.5rem;
  margin-bottom: 3rem;
}
.cart__inner {
  display: flex;
}
.cart__items {
  flex: 0 0 50%;
  display: flex;
  flex-flow: column nowrap;
}
.cart__empty {
  width: 100%;
  padding: 1rem 2rem;
}
.cart__checkout {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - 2rem);
  background: #fff;
  margin-left: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(26, 26, 44, 0.05);
  align-self: flex-start;
  padding: 2rem;
}
.cart__checkout .buttons {
  margin-top: 1rem;
}
.cart__deliveryMessage {
  background: rgba(0, 200, 0, 0.25);
  color: #2e8b57;
  padding: 0.25rem 0.5rem;
  margin: 1rem 0;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
}
.cart__total {
  font-size: 1.5rem;
  font-weight: 900;
  margin-top: 2rem;
  margin-bottom: 0.25rem;
}

@media (max-width: 550px) {
  .cart {
    padding: 3rem 1.5rem;
  }
  .cart__inner {
    flex-direction: column;
  }
  .cart__empty {
    padding: 0;
  }
  .cart__items {
    flex: 0 0 100%;
    margin-bottom: 3rem;
  }
  .cart__checkout {
    flex: 0 0 100%;
    margin-left: 0;
    padding: 1.5rem;
  }
}

.admin input:valid + label {
  left: 0.75rem;
  top: 0.4rem;
  font-size: 0.85rem;
  opacity: 0.25;
}
.admin input[type="checkbox"] + label {
  padding: 0.5rem;
  padding-left: 3rem;
}
.admin input[type="checkbox"]:valid + label {
  left: 0;
  top: 0;
  font-size: 1rem;
  opacity: 1;
}

.cartItem {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  background: #fff;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 0.25rem 0.5rem rgba(26, 26, 44, 0.05);
  transition: all 0.2s;
}
.cartItem:hover {
  transform: scale(1.025);
  box-shadow: 0 0.75rem 0.75rem -0.33rem rgba(26, 26, 44, 0.1);
}
.cartItem + .cartItem {
  margin-top: 1rem;
}
.cartItem__image {
  flex: 0 0 30%;
  height: 0;
  padding-bottom: 30%;
  position: relative;
}
.cartItem__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 1rem;
  position: absolute;
}
.cartItem__details {
  padding: 1rem;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}
.cartItem__footer {
  display: flex;
  margin-top: auto;
  align-items: baseline;
}
.cartItem__price {
  margin: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.cartItem__price del {
  color: #dc143c;
  font-size: 0.85em;
}
.cartItem__buttons {
  margin-left: auto;
  border-radius: 0.25rem;
  overflow: hidden;
  background: #fafafa;
  box-shadow: 0 0.15rem 0.25rem rgba(26, 26, 44, 0.05);
  white-space: nowrap;
}
.cartItem__buttons span {
  padding: 0.5rem;
}
.cartItem__buttons button {
  background: #eee;
  border: none;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.85rem;
  transition: all 0.2s;
}

@media (max-width: 550px) {
  .cartItem__image img {
    padding: 0.75rem;
  }
  .cartItem__price del {
    display: block;
  }
  .cartItem__details {
    padding: 0.75rem 0.75rem 0.75rem 0;
  }
}

.productSingle {
  padding: 5rem 3rem 3rem 3rem;
}
.productSingle__inner {
  display: flex;
}
.productSingle__image {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% / 2.5);
  padding-bottom: calc((100% / 2.5) * 1.33);
  height: 0;
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 0.5rem 1rem rgba(26, 26, 44, 0.05);
  background: #fff;
}
.productSingle img {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: contain;
  padding: 1rem;
}
.productSingle__details {
  padding: 3rem;
  padding-right: 1rem;
  display: flex;
  flex-flow: column nowrap;
}
.productSingle__details .productSingle__footer {
  margin-top: auto;
}
.productSingle__features {
  margin: 0;
  padding: 0;
  margin: 1rem 2rem;
  list-style-type: none;
}
.productSingle__features li {
  position: relative;
  padding: 0;
  line-height: 1.2;
  margin-bottom: 1rem;
}
.productSingle__features li::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #f90;
  border-radius: 5px;
  box-shadow: inset -3px -3px 0 #1a1a2c;
  z-index: 98;
  top: 5px;
  left: -20px;
}
.productSingle__price {
  display: flex;
  align-items: baseline;
  margin: 0.25rem;
}
.productSingle__price + .buttons {
  margin-top: 1.5rem;
}
.productSingle__price h4 {
  font-weight: 900;
  margin: 0;
  margin-right: 0.5rem;
}
.productSingle__price del {
  color: #dc143c;
}
.productSingle__deliveryMessage {
  color: #2e8b57;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}
.productSingle__deliveryMessage .MuiSvgIcon-root {
  margin-right: 0.5rem;
}
.productSingle__suggested > .products {
  margin: 0;
  width: calc(100% + 2rem);
  margin-left: -1rem;
}

@media (max-width: 550px) {
  .productSingle {
    padding: 3rem 1.5rem;
  }
  .productSingle__inner {
    flex-flow: column;
  }
  .productSingle__image {
    flex: 0 0 100%;
    padding-bottom: calc(100% * 1.33);
  }
  .productSingle__details {
    padding: 3rem 0;
  }
  .productSingle__features {
    margin: 1rem 0 1rem 2rem;
  }
}

.payment {
    padding: 5rem 3rem 3rem 3rem;
    max-width: 1080px;
    margin: 0 auto;
  }
  .payment > h4 {
    font-size: 1.5rem;
    margin-bottom: 3rem;
  }
  .payment__inner {
    display: flex;
  }
  .payment {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .payment__success {
    background-color: #d9f9e0; /* Light green for success background */
  }
  
  .payment__product {
    margin-bottom: 10px;
  }
  
  .payment__form {
    margin-top: 20px;
  }
  
  .payment__formGroup {
    margin-bottom: 20px;
  }
  
  .payment__formGroup label {
    display: block;
    margin-bottom: 5px;
  }
  
  .payment__formGroup input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .payment__formGroup input::-webkit-input-placeholder {
    color: #999;
  }
  
  .payment__formGroup input:-ms-input-placeholder {
    color: #999;
  }
  
  .payment__formGroup input::-ms-input-placeholder {
    color: #999;
  }
  
  .payment__formGroup input::placeholder {
    color: #999;
  }
  
  .payment__submitButton {
    background-color: #4caf50; /* Green */
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
  }
  
  .payment__successMessage {
    text-align: center;
    margin-top: 20px;
  }
  
  .payment__successMessage img {
    width: 100px;
    margin-bottom: 10px;
  }
  
.bookmarks > h4 {
  font-size: 1.5rem;
  margin-left: 3rem;
  margin-top: 5rem;
}

.bookmark__inner {
  padding: 3rem;
}

@media (max-width: 550px) {
  .bookmark__inner {
    padding: 1.5rem;
  }
  .bookmarks > h4 {
    margin: 3rem 1.5rem;
  }
}

.welcome {
  padding: 3rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.welcome__header {
  display: flex;
  align-self: flex-start;
  align-items: center;
}
.welcome__avatar {
  height: 5rem;
  border-radius: 99px;
  margin-right: 1.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(26, 26, 44, 0.15);
}
.welcome__header span {
  margin-top: 4rem;
}
.welcome__inner {
  display: flex;
}
.welcome .form__split {
  display: flex;
  background: #fff;
  border-radius: 0.5rem;
  overflow: hidden;
  padding: 2rem;
  box-shadow: 0 0.25rem 0.5rem rgba(26, 26, 44, 0.05);
}
.welcome .form {
  margin: 0;
  box-shadow: none;
  padding: 1rem;
}
.welcome .form + .form {
  margin-left: 1rem;
}
.welcome .form h5 {
  margin-bottom: 1rem;
}

@media (max-width: 550px) {
  .welcome {
    padding: 1.5rem;
  }
  .welcome .form__split {
    flex-direction: column;
    padding: 1.5rem;
  }
  .welcome .form {
    padding: 0;
  }
  .welcome .form + .form {
    margin-left: 0;
    margin-top: 1.5rem;
  }
  .welcome__header {
    flex-direction: column;
    align-items: flex-start;
  }
  .welcome__avatar {
    margin-right: 0;
  }
  .welcome__header span {
    margin-top: 1.5rem;
  }
}

.profile {
  padding: 3rem;
}
.profile__header {
  display: flex;
  align-self: flex-start;
  align-items: center;
}
.profile__avatar {
  height: 5rem;
  border-radius: 99px;
  margin-right: 1.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(26, 26, 44, 0.15);
}
.profile__header span {
  margin-top: 4rem;
}
.profile__inner {
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(26, 26, 44, 0.05);
  padding: 2rem;
  margin-top: 3rem;
}
.profile__inner p {
  margin: 0;
  padding-bottom: 1rem;
  display: flex;
}
.profile__inner p span:first-child {
  flex: 0 0 20%;
  margin-right: 2rem;
  max-width: 200px;
  font-weight: 700;
}
.profile__inner p span:nth-child(2) {
  flex: 80% 1;
}

@media (max-width: 550px) {
  .profile {
    padding: 1.5rem;
  }
  .profile__inner p {
    flex-direction: column;
  }
  .profile__inner p span:first-child,
  .profile__inner p span:nth-child(2) {
    flex: auto;
  }
  .profile__inner {
    padding: 1.5rem;
    margin-top: 1.5rem;
  }
  .profile__header {
    flex-direction: column;
    align-items: flex-start;
  }
  .profile__avatar {
    margin-right: 0;
  }
  .profile__header span {
    margin-top: 1.5rem;
  }
}

.manage-products {
  padding: 20px;
  background-color: #f7f7f7;
}

h3 {
  font-size: 1.5rem; /* Adjust the heading font size */
  margin-bottom: 20px; /* Add space below the heading */
  text-align: center; /* Center the heading text */
}

form {
  .form-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px; /* Increase space between form fields */

    label {
      font-weight: bold;
      font-size: 1rem; /* Adjust the label font size */
      margin-bottom: 5px;
    }

    input {
      padding: 10px; /* Add padding for better input field appearance */
      border: 1px solid #ccc; /* Add a border */
      border-radius: 4px; /* Add border radius for rounded corners */
      font-size: 1rem; /* Adjust the input font size */
    }
  }

  .form-buttons {
    display: flex;
    justify-content: space-between; /* Align buttons horizontally */

    button {
      padding: 10px 20px; /* Add padding to the buttons */
      border: none; /* Remove button border */
      border-radius: 4px; /* Add border radius for rounded buttons */
      font-size: 1rem; /* Adjust button font size */
    }
  }
}

.manage-products h4 {
  font-size: 20px;
  margin-bottom: 10px;
}

.manage-products__inner {
  display: flex;
  flex-wrap: wrap;
}

.manage-products__summary {
  background-color: #fff;
  border: 1px solid #ccc;
  margin: 10px;
  padding: 20px;
  width: calc(33.33% - 20px);
  box-sizing: border-box;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}

.manage-products__summary h5 {
  font-size: 18px;
  margin-bottom: 10px;
}

.manage-products__summary p {
  font-size: 16px;
  margin-bottom: 10px;
}

.product__list {
  margin-top: 10px;
}

.manage-products__item {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin-top: 10px;
}

.manage-products__name {
  font-weight: 600;
}

.manage-products__price {
  font-weight: 600;
  color: #0077cc;
}

.edit-product-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  z-index: 999; /* Adjust the z-index as needed to ensure it's on top */

  .edit-product-popup {
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Apply elevation effect */
    padding: 20px;
    border-radius: 4px;
  }
  .edit-product-popup button{
    background-color: #e74c3c;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
  }

}

/* CSS for the delete confirmation pop-up */
.delete-product-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above other content */
}

.delete-product-popup {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.delete-product-popup h3 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.delete-product-popup p {
  font-size: 16px;
  margin: 10px 0;
}

.delete-product-popup button {
  background-color: #e74c3c;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.delete-product-popup button:hover {
  background-color: #c0392b;
}

/* ProductCard.css */
.product-card {
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 8px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.product-card:hover {
  transform: translateY(-5px);
}

.product-image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 10px;
}

.product-name {
  font-size: 1.2rem;
  color: #333;
  margin: 0;
}

.product-category {
  font-size: 0.9rem;
  color: #777;
  margin: 5px 0;
}

.product-price {
  font-size: 1rem;
  color: #007bff;
  margin: 5px 0;
}

.product-rating {
  font-size: 0.9rem;
  color: #555;
  margin: 5px 0;
}

.app {
  width: 100vw;
  min-height: 100vh;
  position: relative;
  background: #fafafa;
  display: flex;
  flex-flow: column nowrap;
}

.app__inner {
  padding-left: clamp(80px, 10vw, 100px);
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}
.whatsapp_icon {
  z-index: 999;
}

img {
  border: none;
}

@media (max-width: 550px) {
  .app__inner {
    padding: 0;
  }
}

