.orders {
  padding: 5rem 3rem 3rem 3rem;
}
.orders > h4 {
  font-size: 1.5rem;
  margin-bottom: 3rem;
}
.orders__inner {
  display: flex;
  width: calc(100% + 2rem);
  margin-left: -1rem;
  flex-flow: row wrap;
}
.orders .payment__summary {
  flex: 0 0 calc(100% / 3 - 2rem);
  width: calc(100% / 3 - 2rem);
  margin: 1rem;
  max-width: auto;
  align-self: stretch;
  display: flex;
  flex-flow: column nowrap;
}
.order__list {
  display: flex;
  overflow: scroll;
  margin: 1rem 0;

  flex: 1;
  scroll-snap-type: x mandatory;
}

.order__item {
  display: flex;
  flex-direction: column;
  min-width: 120px;
  flex: 0.4;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
  background: rgba(0, 0, 0, 0.01);
  position: relative;
  overflow: hidden;
  scroll-snap-align: start;
}
.order__quantity,
.order__price {
  position: absolute;
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
  font-size: 0.6rem;
  font-weight: bold;
  background: rgba(26, 26, 44, 0.05);
  width: fit-content;
  color: #1a1a2c;
  left: 1rem;
  bottom: 2.5rem;
}
.order__quantity {
  left: auto;
  right: 0.5rem;
  bottom: auto;
  top: 0.5rem;
}
.order__image {
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  position: relative;
}
.order__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 0.5rem;
  position: absolute;
}
.order__name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.order__item + .order__item {
  margin-left: 1rem;
}
@media (max-width: 850px) {
  .orders .payment__summary {
    flex: 0 0 calc(100% / 2 - 2rem);
    width: calc(100% / 2 - 2rem);
  }
}

@media (max-width: 550px) {
  .orders {
    padding: 3rem 1.5rem;
  }
  .orders__inner {
    flex-flow: column nowrap;
    width: 100%;
    margin: 0;
  }
  .orders .payment__summary {
    flex: 0 0 100%;
    width: 100%;
    margin: 0.75rem 0;
  }
}
