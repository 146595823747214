.results {
  max-width: 600px;
  width: calc(100% - 2rem);
  max-height: 24rem;
  overflow: scroll;
  margin: 1rem;
  border-radius: 1rem;
  box-shadow: 0 0 0 2px white, 0 0.25rem 1rem -0.25rem rgba(26, 26, 44, 0.15);
  position: absolute;
  top: calc(100% + 0.5rem);
  right: 3rem;
  z-index: 9999;
  background: #fff;
}
.result {
  display: flex;
  align-items: center;
  transition: background 0.2s;
  cursor: pointer;
}

.result:hover {
  background: rgba(26, 26, 44, 0.05);
}
.result__image {
  width: 4rem;
  flex: 0 0 4.5rem;
  height: 0;
  padding-bottom: 4.5rem;
  background: #fff;
  position: relative;
}
.result__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 0.75rem;
  position: absolute;
}

.result span {
  overflow: hidden;
  padding: 1rem;
  padding-left: 0.5rem;
}

.result__title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
  margin: 0;
  font-size: 0.95rem;
  line-height: 1.2;
  font-weight: 500;
}
.result__desc {
  font-size: 0.85rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  display: block;
  opacity: 0.7;
}

@media (max-width: 750px) {
  .results {
    width: calc(100% - 3rem);
    max-height: 22rem;
    overflow: scroll;
    margin: 1rem 1.5rem;
    right: 0;
    top: calc(100%);
    box-shadow: 0 0 0 2px white,
      0 0.25rem 1.5rem -0.25rem rgba(26, 26, 44, 0.25);
  }
}
