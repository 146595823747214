.payment {
    padding: 5rem 3rem 3rem 3rem;
    max-width: 1080px;
    margin: 0 auto;
  }
  .payment > h4 {
    font-size: 1.5rem;
    margin-bottom: 3rem;
  }
  .payment__inner {
    display: flex;
  }
  .payment {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .payment__success {
    background-color: #d9f9e0; /* Light green for success background */
  }
  
  .payment__product {
    margin-bottom: 10px;
  }
  
  .payment__form {
    margin-top: 20px;
  }
  
  .payment__formGroup {
    margin-bottom: 20px;
  }
  
  .payment__formGroup label {
    display: block;
    margin-bottom: 5px;
  }
  
  .payment__formGroup input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .payment__formGroup input::placeholder {
    color: #999;
  }
  
  .payment__submitButton {
    background-color: #4caf50; /* Green */
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
  }
  
  .payment__successMessage {
    text-align: center;
    margin-top: 20px;
  }
  
  .payment__successMessage img {
    width: 100px;
    margin-bottom: 10px;
  }
  