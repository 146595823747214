.bookmarks > h4 {
  font-size: 1.5rem;
  margin-left: 3rem;
  margin-top: 5rem;
}

.bookmark__inner {
  padding: 3rem;
}

@media (max-width: 550px) {
  .bookmark__inner {
    padding: 1.5rem;
  }
  .bookmarks > h4 {
    margin: 3rem 1.5rem;
  }
}
