.login,
.signup,
.passwordReset {
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
}
.form {
  margin: 5rem 3rem 3rem 3rem;
  padding: 2rem 1rem;
  background: #fff;
  background: #fff;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 0.25rem 0.5rem rgba(26, 26, 44, 0.05);
}
.form h4 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}
.form p.error {
  padding: 0.25rem 0.5rem;
  color: #dc143c;
  font-size: 0.85rem;
}
.form .form__error.error {
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  background: rgba(255, 0, 0, 0.05);
  border-radius: 0.5rem;
  width: 100%;
  max-width: 20rem;
  text-overflow: ellipsis;
  overflow: hidden;
}
.form .form__error.error.green {
  background: rgba(0, 200, 0, 0.25);
  color: #2e8b57;
}
.form span,
.form__resetLink {
  text-align: center;
  padding: 0.5rem;
  display: block;
}
.signup .form__resetLink {
  margin-bottom: 1rem;
}
.form hr {
  background: #ddd;
  width: 90%;
  margin: 0.5rem 0;
  margin-left: 5%;
}
.form__element {
  position: relative;
}
.form__element + .form__element {
  margin-top: 1rem;
}
.form__element label {
  position: absolute;
  left: 1rem;
  top: 1rem;
  font-size: 1rem;
  opacity: 0.75;
  transition: all 0.2s;
}
.form__element .form__icon {
  opacity: 0.25;
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.form__element .form__icon.active {
  opacity: 1;
}
.form__element input,
select {
  border: none;
  background: #fafafa;
  border-radius: 0.5rem;
  padding: 1.5rem 0.75rem 0.5rem 0.75rem;
  box-shadow: inset 0 0 0.5rem rgba(26, 26, 44, 0.05);
  outline: none;
  width: 20rem;
  font-weight: 700;
  transition: all 0.2s;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.form__element input[type="password"] {
  padding: 1.5rem 3.5rem 0.5rem 0.75rem;
}
.form__element input:hover,
select:hover {
  box-shadow: inset 0 0 0.75rem rgba(26, 26, 44, 0.15);
}
.form__element input:focus,
select:focus {
  box-shadow: inset 0 0 0 2px rgba(26, 26, 44, 0.25);
}
.form__element input.error {
  box-shadow: inset 0 0 0 2px #dc143c;
}
input.active + label,
input:focus + label,
select.active + label,
select:focus + label {
  left: 0.75rem;
  top: 0.4rem;
  font-size: 0.85rem;
  opacity: 0.25;
}
.form__element button {
  width: 100%;
  padding: 1rem 0.75rem !important;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  transform: none !important;
  margin: 0 !important;
}
.login .button.signup {
  margin-top: 1rem !important;
}

.form__element.buttons {
  flex-direction: column;
}
.form__element button.outline {
  margin-left: 0;
  padding: 0.375rem 0.75rem !important;
  margin-top: 1rem !important;
}

.form__element button.outline img {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}
input[type="checkbox"],
input[type="radio"] {
  width: 0;
  height: 0;
  position: relative;
  display: none;
  visibility: hidden;
}
input[type="checkbox"] + label,
input[type="radio"] + label {
  position: relative;
  border: none;
  border-radius: 0.5rem;
  padding: 1rem;
  padding-left: 3rem;
  outline: none;
  width: 20rem;
  font-weight: 700;
  left: 0;
  opacity: 0.25;
  top: 0;
}
input[type="radio"] + label {
  padding: 0.25rem 1rem 0.25rem 3rem;
  opacity: 1;
}
input[type="checkbox"] + label::before,
input[type="checkbox"] + label::after,
input[type="radio"] + label::before,
input[type="radio"] + label::after {
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  content: "";
  background: #fff;
  border: 2px solid #1a1a2c;
  border-radius: 0.25rem;
  left: 1rem;
}
input[type="radio"] + label::before,
input[type="radio"] + label::after {
  border-radius: 99px;
}
input[type="checkbox"] + label::after,
input[type="radio"] + label::after {
  background: #f90;
  border: none;
  transform: scale(0);
  transition: all 0.2s;
}
input[type="checkbox"]:checked + label,
input[type="radio"]:checked + label {
  left: 0;
  top: 0;
  font-size: 1rem;
  opacity: 1;
}
input[type="checkbox"]:checked + label::after,
input[type="radio"]:checked + label::after {
  transform: scale(0.6);
}

@media (max-width: 550px) {
  .form {
    margin: 1.5rem;
  }
  .form__element input,
  select,
  input[type="checkbox"] + label,
  input[type="radio"] + label {
    width: 100%;
    max-width: 18rem;
  }
}
