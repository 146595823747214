.categories {
  margin: 1rem 3rem -1rem 3rem;
  display: flex;
  white-space: nowrap;
  overflow: scroll;
  -webkit-mask-image: linear-gradient(
    to right,
    transparent 0%,
    black 3%,
    black 97%,
    transparent 100%
  );
}
.categories::-webkit-scrollbar {
  display: none;
}
.categories__category {
  flex: 0 0 auto;
  font-size: 1.15rem;
  position: relative;
  padding: 1rem;
  opacity: 0.5;
  transition: opacity 0.2s;
  cursor: pointer;
  text-transform: capitalize;
}
.categories__category:hover {
  opacity: 1;
}
.categories__category::before {
  position: absolute;
  left: 1rem;
  content: "";
  width: 0;
  height: 1px;
  background: #1a1a2c;
  bottom: 0.5rem;
  transition: all 0.2s;
}
.categories__category:hover::before {
  width: 33%;
}
.categories__category.active {
  font-weight: 700;
  opacity: 1;
}
.categories__category.active::before {
  width: calc(100% - 4rem);
  left: 2rem;
}
.categories__category + .categories__category {
  margin: 0;
  margin-left: 1rem;
}

@media (max-width: 550px) {
  .categories {
    margin: 1.5rem 1.5rem 1rem 1.5rem;
  }
}
